/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 * For more information on global stylesheets, visit the documentation:
 * https://ionicframework.com/docs/layout/global-stylesheets
 */

/* Core CSS required for Ionic components to work properly */
@import "~@ionic/angular/css/core.css";

/* Basic CSS for apps built with Ionic */
@import "~@ionic/angular/css/normalize.css";
@import "~@ionic/angular/css/structure.css";
@import "~@ionic/angular/css/typography.css";
@import '~@ionic/angular/css/display.css';

/* Optional CSS utils that can be commented out */
@import "~@ionic/angular/css/padding.css";
@import "~@ionic/angular/css/float-elements.css";
@import "~@ionic/angular/css/text-alignment.css";
@import "~@ionic/angular/css/text-transformation.css";
@import "~@ionic/angular/css/flex-utils.css";


// global.scss
@import '~swiper/scss';
@import '~swiper/scss/autoplay';
@import '~swiper/scss/keyboard';
@import '~swiper/scss/pagination';
@import '~swiper/scss/scrollbar';
@import '~swiper/scss/zoom';
@import '~@ionic/angular/css/ionic-swiper';


ion-item.item-has-focus {
    --highlight-background: yellow;
}


.alertCustomCss {
    --background: #e64f4f;
    font-size: 20px;


    .alert-wrapper {
        // width: 100%;
        max-width: 90%;
        border-color: rgba(255, 255, 255, 0.87);
        border-width: 3px;
        border-style: solid;
        border-radius: 15px;

        h2 {
            color: white;
            font-size: 30px;
            font-weight: bolder;
        }

        .alert-button {
            color: white;
            font-size: 28px;
            font-weight: bolder;

            border-color: rgba(255, 255, 255, 0.87);
            border-width: 3px;
            border-style: solid;
            border-radius: 15px;
            border-radius: 10px;
            background: rgb(57, 111, 172);
        }
    }
}


ion-item.item-has-focus {
    --highlight-background: rgb(255, 255, 255);
}